<template>
  <div>
    <section
      id="hero-home"
      class="hero has-text-centered is-dark hero-emphasis"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column feature-text">
              <h1 class="title has-text-white is-size-1">Optimisation<br /></h1>
              <h3 class="subtitle has-text-light-grey is-size-5">
                Ensuring your systems are performing optimally.
              </h3>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="feature-content columns">
      <h1 class="column is-size-4">
        We understand that often a deployed system may be still able to do what
        it was intended to, but its <span>speed</span> and
        <span>efficiency</span> in performing tasks may leave something to be
        desired. That is where we can help. <br /><br />
        We have been engaged on several occasions to look into system
        <span>performance problems</span>, <span>diagnose errors</span>,
        <span>propose solutions</span> and <span>implement</span> these to help
        optimise the performance of an enormous range of systems and
        applications. Our optimisation services include
        <span> HTTP stack optimisation </span> and
        <span>search engine optimisation (SEO) strategies</span>, as well as a
        whole lot more. <br /><br />
        So if your systems seem to be running sub-optimally or if you just need
        some experienced professionals to run a health check and provide some
        educated recommendations, then <span>contact us now</span>.
      </h1>
    </section>
  </div>
</template>

<style scoped>
@import "../../styles/feature-content.scss";
.hero-body {
  background: url("/img/service-banner/optimisation-banner.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.8);
  background-blend-mode: darken;
}
#hero-home {
  position: relative;
}
#hero-home:after {
  content: "\A";
  background-image: url("/img/service-banner/optimisation-banner.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>

<script>
export default {
  name: "Optimisation"
}
</script>
